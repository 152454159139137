import React from 'react';
import { Link } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';

const PrivacyPolicyPage = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <PrivacyPolicy />
      <div className="mt-4">
        <Link to="/" className="text-purple-600 hover:underline">Back to Home</Link>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;