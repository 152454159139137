import localforage from 'localforage';
import practicesData from '../practices.json';  // Adjust this path if needed

const PRACTICES_STORE = 'practices';
const LAST_UPDATE_KEY = 'lastPracticeUpdate';
const UPDATE_INTERVAL = 7 * 24 * 60 * 60 * 1000; // 1 week

export const practiceDataUtil = {
  async checkForUpdates() {
    try {
      const lastUpdate = await localforage.getItem(LAST_UPDATE_KEY);
      const shouldUpdate = !lastUpdate || (Date.now() - lastUpdate > UPDATE_INTERVAL);

      if (shouldUpdate) {
        await this.updateLocalData();
        return true; // Data was updated
      }
      return false; // No update was needed
    } catch (error) {
      console.error('Error checking for updates:', error);
      return false;
    }
  },

  async getPracticesByCodes(practiceCodes) {
    const allPractices = await this.getAllPractices();
    return allPractices.filter(practice => practiceCodes.includes(practice.prac_code));
  },

  async updateLocalData() {
    try {
      console.log('Updating local data with', practicesData.length, 'practices');
      await localforage.setItem(PRACTICES_STORE, practicesData);
      await localforage.setItem(LAST_UPDATE_KEY, Date.now());
    } catch (error) {
      console.error('Error updating local data:', error);
      throw error;
    }
  },

  async initializePracticeData(forceUpdate = false) {
    try {
      const practices = await localforage.getItem(PRACTICES_STORE);
      if (!practices || forceUpdate) {
        await this.updateLocalData();
        console.log('Practice data has been initialized/updated.');
      }
      return this.getAllPractices();
    } catch (error) {
      console.error('Error initializing practice data:', error);
      throw error;
    }
  },

  async getTopPractices(limit = 5) {
    try {
      const practices = await this.getAllPractices();
      return practices.slice(0, limit);
    } catch (error) {
      console.error('Error getting top practices:', error);
      return [];
    }
  },

  normalizePostcode(postcode) {
    return postcode.replace(/\s+/g, '').toUpperCase();
  },

  async searchPractices(searchTerm) {
    try {
      const practices = await this.getAllPractices();
      const normalizedSearchTerm = this.normalizePostcode(searchTerm.toLowerCase());
      return practices.filter(practice => 
        practice.prac_name.toLowerCase().includes(normalizedSearchTerm) ||
        this.normalizePostcode(practice.postcode).includes(normalizedSearchTerm)
      );
    } catch (error) {
      console.error('Error searching practices:', error);
      return [];
    }
  },

  async getAllPractices() {
    try {
      let practices = await localforage.getItem(PRACTICES_STORE);
      if (!practices) {
        await this.updateLocalData();
        practices = await localforage.getItem(PRACTICES_STORE);
      }
      if (!practices) {
        throw new Error('No practices found in local storage');
      }
      console.log('Retrieved', practices.length, 'practices from local storage');
      return practices;
    } catch (error) {
      console.error('Error getting all practices:', error);
      throw error;
    }
  }
};