import * as React from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker } from "react-day-picker";

export function Calendar({ className, showOutsideDays = true, ...props }) {
  return (
    <DayPicker
    showOutsideDays={showOutsideDays}
  className={`p-3 bg-white rounded-lg shadow ${className}`}
  classNames={{
    months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
    month: "space-y-4",
    caption: "flex justify-center pt-1 relative items-center",
    caption_label: "text-sm font-medium",
    nav: "flex items-center justify-between",
    nav_button: "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute top-1",
    nav_button_previous: "left-1",
    nav_button_next: "right-1",
    table: "w-full border-collapse space-y-1",
    head_row: "flex",
    head_cell: "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
    row: "flex w-full mt-2",
    cell: "h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
    day: "h-9 w-9 p-0 font-normal aria-selected:opacity-100",
    day_selected: "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
    day_today: "bg-accent text-accent-foreground",
    day_outside: "text-muted-foreground opacity-50",
    day_disabled: "text-muted-foreground opacity-50",
    day_range_middle: "aria-selected:bg-accent aria-selected:text-accent-foreground",
    day_hidden: "invisible",
    ...props.classNames,
  }}
  components={{
    IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" {...props} />,
    IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" {...props} />,
  }}
  {...props}
    />
  );
}

Calendar.displayName = "Calendar";