import React from 'react';

export function Alert({ children, variant = "default", ...props }) {
  const variantClasses = {
    default: "bg-background text-foreground",
    destructive: "bg-destructive text-destructive-foreground",
  };

  return (
    <div role="alert" className={`rounded-lg border p-4 ${variantClasses[variant]}`} {...props}>
      {children}
    </div>
  );
}

export function AlertDescription({ children, ...props }) {
  return <div className="text-sm" {...props}>{children}</div>;
}