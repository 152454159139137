import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { decryptData, encryptData } from './encryptionUtil';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const db = getFirestore();

const getNextInvoiceNumber = async (userId, userInitials, existingUserData = null) => {
  console.log('getNextInvoiceNumber called with:', { userId, userInitials });
  try {
    let userData;
    if (existingUserData) {
      userData = existingUserData;
      console.log('Using existing user data:', userData);
    } else {
      console.log('Fetching user data from Firestore');
      const docRef = doc(db, 'users', userId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        userData = docSnap.data();
        console.log('Fetched user data:', userData);
      } else {
        console.log('No user data found in Firestore');
        userData = {};
      }
    }

    let nextNumber = 1;
    if (userData.lastInvoiceNumber) {
      console.log('Encrypted lastInvoiceNumber:', userData.lastInvoiceNumber);
      try {
        const decryptedLastNumber = decryptData(userData.lastInvoiceNumber);
        console.log('Decrypted lastInvoiceNumber:', decryptedLastNumber);
        
        // Extract the number part from the decrypted invoice number
        const lastNumberMatch = decryptedLastNumber.match(/\d+$/);
        if (lastNumberMatch) {
          nextNumber = parseInt(lastNumberMatch[0], 10) + 1;
        } else {
          console.log('Could not extract number from decrypted lastInvoiceNumber, using default');
        }
        console.log('Next invoice number:', nextNumber);
      } catch (error) {
        console.error('Error decrypting or parsing lastInvoiceNumber:', error);
      }
    } else {
      console.log('No existing lastInvoiceNumber, using default:', nextNumber);
    }

    const invoiceNumber = `${userInitials}${String(nextNumber).padStart(4, '0')}`;
    console.log('Generated invoice number:', invoiceNumber);

    // Encrypt and store the new invoice number
    const encryptedNextNumber = encryptData(invoiceNumber);
    console.log('Encrypted new invoice number:', encryptedNextNumber);
    console.log('Updating Firestore with new lastInvoiceNumber');
    try {
      await updateDoc(doc(db, 'users', userId), { 
        lastInvoiceNumber: encryptedNextNumber,
        lastUpdated: new Date()
      });
      console.log('Firestore update successful');
    } catch (error) {
      console.error('Error updating Firestore:', error);
      throw error;
    }
    
    return invoiceNumber;
  } catch (error) {
    console.error('Error in getNextInvoiceNumber:', error);
    return `${userInitials}0001`; // Return a default invoice number
  }
};

export const generatePDF = async (summary, total, practiceDetails, rateType, userProfile) => {
  console.log('generatePDF called with:', {
    summary: summary ? 'Present' : 'Missing',
    total,
    practiceDetails: practiceDetails ? 'Present' : 'Missing',
    rateType,
    userProfile: userProfile ? {
      id: userProfile.id ? 'Present' : 'Missing',
      name: userProfile.name ? 'Present' : 'Missing',
    } : 'Missing'
  });

  if (!userProfile || !userProfile.id) {
    console.error('UserProfile details:', userProfile);
    userProfile = {
      id: 'default',
      name: 'Default User',
    };
    console.log('Using default userProfile:', userProfile);
  }

  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.width;
  const pageHeight = doc.internal.pageSize.height;
  const margin = 10;

  // Colors
  const purple = [128, 0, 128];
  const lightPurple = [250, 245, 255];

  // Helper functions
  const safeAddText = (text, x, y, options = {}) => {
    if (text != null && text !== undefined && text !== 'NA' && text !== 'N/A' && text !== 'undefined') {
      doc.text(text.toString(), x, y, options);
    }
  };

  const rightAlignText = (text, x, y) => {
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize() / doc.internal.scaleFactor;
    safeAddText(text, x - textWidth, y);
  };

  const wrapText = (text, x, y, maxWidth, lineHeight) => {
    if (text == null || text === undefined || text === 'NA' || text === 'N/A' || text === 'undefined') return 0;
    const lines = doc.splitTextToSize(text, maxWidth);
    lines.forEach((line, i) => {
      safeAddText(line, x, y + (i * lineHeight));
    });
    return lines.length;
  };

  const generateUniqueFilename = () => {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `Invoice_${month}-${year}-${hours}:${minutes}.pdf`;
  };

  // Generate invoice number
  let invoiceNumber = 'INV0001';
  let userInitials = 'IN';
  let decryptedName = '';

  try {
    decryptedName = userProfile.name ? decryptData(userProfile.name) : '';
    console.log('Decrypted name:', decryptedName);
  
    const nameParts = decryptedName.split(' ').filter(part => part.length > 0);
    if (nameParts.length >= 2) {
      userInitials = (nameParts[0][0] + nameParts[nameParts.length - 1][0]).toUpperCase();
    } else if (nameParts.length === 1) {
      userInitials = nameParts[0].substring(0, 2).toUpperCase();
    }
    console.log('User initials:', userInitials);
  
    invoiceNumber = await getNextInvoiceNumber(userProfile.id, userInitials, userProfile);
    console.log('Received invoice number:', invoiceNumber);
  } catch (error) {
    console.error('Error generating invoice number:', error);
    // If there's an error, we'll use the default invoice number
  }

  // Add title
  doc.setFontSize(18);
  doc.setTextColor(...purple);
  safeAddText('Provision of Dental Services', margin, 20);
  
  // Add purple line below title
  doc.setDrawColor(...purple);
  doc.setLineWidth(0.5);
  doc.line(margin, 22, pageWidth - margin, 22);

  // Add INVOICE text and number
  doc.setTextColor(0);
  doc.setFontSize(16);
  rightAlignText('INVOICE', pageWidth - margin, 30);
  doc.setFontSize(12);
  rightAlignText(`Invoice No: ${invoiceNumber}`, pageWidth - margin, 38);

  // Add invoice date
  const currentDate = new Date().toLocaleDateString('en-GB');
  doc.setFontSize(12);
  safeAddText(`Date: ${currentDate}`, margin, 40);

  // Add practice details
  doc.setFontSize(10);
  safeAddText('To:', margin, 50);
  let toY = 55;
  if (practiceDetails) {
    toY += wrapText(practiceDetails.prac_name, margin, toY, 80, 5) * 5;
    toY += wrapText(practiceDetails.address1, margin, toY, 80, 5) * 5;
    if (practiceDetails.address2) toY += wrapText(practiceDetails.address2, margin, toY, 80, 5) * 5;
    toY += wrapText(practiceDetails.city, margin, toY, 80, 5) * 5;
    toY += wrapText(practiceDetails.postcode, margin, toY, 80, 5) * 5;
  } else {
    safeAddText('Practice details not available', margin, toY);
    toY += 5;
  }

  // Add hygienist details
  safeAddText('From:', 120, 50);
  let fromY = 55;
  if (userProfile) {
    fromY += wrapText(decryptedName, 120, fromY, 80, 5) * 5;
    fromY += wrapText(decryptData(userProfile.address1), 120, fromY, 80, 5) * 5;
    if (userProfile.address2) fromY += wrapText(decryptData(userProfile.address2), 120, fromY, 80, 5) * 5;
    fromY += wrapText(decryptData(userProfile.city), 120, fromY, 80, 5) * 5;
    fromY += wrapText(decryptData(userProfile.postcode), 120, fromY, 80, 5) * 5;
  } else {
    safeAddText('Hygienist details not available', 120, fromY);
    fromY += 5;
  }

  // Determine start Y for the table
  const tableStartY = Math.max(toY, fromY) + 10;

  // Add invoice description table
  const tableColumns = rateType === 'hourly' 
    ? [['Date', 'Description', 'Amount Payable']]
    : [['Date', 'No. of Patients', 'Description', 'Amount Payable']];

    const tableRows = summary.map(item => {
      if (rateType === 'hourly') {
        const description = `${item.patients[0].count} hours @ £${parseFloat(item.patients[0].rate).toFixed(2)}/hour`;
        const fullDescription = item.patients[0].description 
          ? `${description} (${item.patients[0].description})` 
          : description;
        return [item.formattedDate || '', fullDescription, `£${item.amount || '0.00'}`];
      } else {
        const uniqueRates = new Map();
        
        item.patients.forEach(patient => {
          const key = `${patient.rate}_${patient.description}`;
          if (uniqueRates.has(key)) {
            uniqueRates.get(key).count += parseInt(patient.count) || 0;
          } else {
            uniqueRates.set(key, { ...patient, count: parseInt(patient.count) || 0 });
          }
        });
      
        const description = Array.from(uniqueRates.values())
          .map(patient => {
            let line = `${patient.count}x £${parseFloat(patient.rate).toFixed(2)} @ ${item.rateValue}%`;
            if (patient.description && patient.description.trim() !== '') {
              line += ` (${patient.description.trim()})`;
            }
            return line;
          })
          .join('\n');
      
        return [item.formattedDate || '', item.totalPatients.toString(), description, `£${item.amount || '0.00'}`];
      }
    });

  doc.autoTable({
    startY: tableStartY,
    head: tableColumns,
    body: tableRows,
    theme: 'grid',
    styles: { fontSize: 10, cellPadding: 2 },
    headStyles: { fillColor: purple, halign: 'left', textColor: [255, 255, 255] },
    columnStyles: {
      [tableColumns[0].length - 1]: { halign: 'right' }
    },
    didParseCell: function(data) {
      if (data.section === 'head' && data.column.index === tableColumns[0].length - 1) {
        data.cell.styles.halign = 'right';
      }
    },
    didDrawCell: function(data) {
      if (data.section === 'body' && data.column.index === (rateType === 'hourly' ? 1 : 2)) {
        const cell = data.cell;
        doc.setFontSize(10); // Set font size to match the rest of the table
        // We're no longer manually rendering the text here
        // This allows autoTable to handle the text rendering and prevents duplication
        return true; // Let autoTable draw this cell
      }
    }
  });

  // Add total
  const finalY = doc.lastAutoTable.finalY || tableStartY;
  doc.setFontSize(12);
  doc.setFont(undefined, 'bold');
  const totalText = `Total: £${total || '0.00'}`;
  rightAlignText(totalText, pageWidth - margin, finalY + 10);

  // Add payment details
  doc.setFont(undefined, 'normal');
  doc.setFontSize(10);
  let paymentY = finalY + 20;
  safeAddText('Payment Options:', margin, paymentY);
  paymentY += 5;
  if (userProfile) {
    safeAddText(decryptData(userProfile.bankName), margin, paymentY);
    paymentY += 5;
    const decryptedAccountNumber = decryptData(userProfile.accountNumber);
    safeAddText(`Account No: ${decryptedAccountNumber}`, margin, paymentY);
    paymentY += 5;
    const decryptedSortCode = decryptData(userProfile.sortCode);
    safeAddText(`Sort Code: ${decryptedSortCode}`, margin, paymentY);
    paymentY += 5;
  } else {
    safeAddText('Bank details not available', margin, paymentY);
    paymentY += 5;
  }
  safeAddText('Payment is due within 30 days from date of invoice.', margin, paymentY + 5);

  // Save the PDF
  const uniqueFilename = generateUniqueFilename();
  doc.save(uniqueFilename);

  console.log('PDF generation completed');
};