import React, { useState, useEffect } from 'react';
import { Card, CardContent } from "./components/ui/card";
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import { Alert, AlertDescription } from "./components/ui/alert";
import { practiceDataUtil } from './helpers/practiceDataUtil';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase';


const PracticeSettings = ({ onClose, onPracticeSelect, initialSelectedPractices = [],user }) => {
  const [allPractices, setAllPractices] = useState([]);
  const [displayedPractices, setDisplayedPractices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPracticeCodes, setSelectedPracticeCodes] = useState(
    new Set(initialSelectedPractices.map(p => p.prac_code))
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    if (searchTerm.trim()) {
      searchPractices();
    } else {
      updateDisplayedPractices();
    }
  }, [searchTerm, allPractices, selectedPracticeCodes]);

  const initializeData = async () => {
    setLoading(true);
    setError(null);
    try {
      await practiceDataUtil.initializePracticeData(true);
      const allPractices = await practiceDataUtil.getAllPractices();
  
      let savedPracticeCodes = new Set();
  
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (Array.isArray(userData.selectedPractices)) {
            // If Firebase has selected practices, use them
            savedPracticeCodes = new Set(userData.selectedPractices);
          }
        }
      }
  
      // If no data in Firebase, fallback to localStorage
      if (savedPracticeCodes.size === 0) {
        savedPracticeCodes = new Set(JSON.parse(localStorage.getItem('selectedPractices') || '[]'));
      }
  
      setAllPractices(allPractices);
      setSelectedPracticeCodes(savedPracticeCodes);
      updateDisplayedPractices(allPractices, savedPracticeCodes);
  
    } catch (err) {
      console.error('Error initializing practice data:', err);
      setError('Unable to load practices. Please try again later.');
    }
    setLoading(false);
  };
  

  const updateDisplayedPractices = (practices = allPractices, selected = selectedPracticeCodes, limit = 5) => {
    const selectedPractices = practices.filter(p => selected.has(p.prac_code));
    const unselectedPractices = practices.filter(p => !selected.has(p.prac_code));
    const displayPractices = limit 
      ? [...selectedPractices, ...unselectedPractices.slice(0, Math.max(limit, limit - selectedPractices.length))]
      : [...selectedPractices, ...unselectedPractices];
    setDisplayedPractices(displayPractices);
  };

  const searchPractices = async () => {
    setLoading(true);
    setError(null);
    try {
      // Only trigger search if search term has 3 or more characters
      if (searchTerm.trim().length >= 3) {
        const normalizedSearchTerm = searchTerm.toLowerCase().replace(/\s+/g, ' ').split(' '); // Split search term into words
  
        const searchResults = allPractices.filter(practice => {
          // Normalize the practice fields (name, address, postcode)
          const normalizedPractice = {
            prac_name: practice.prac_name?.toLowerCase() || '',
            address1: practice.address1?.toLowerCase() || '',
            address2: practice.address2?.toLowerCase() || '',
            address3: practice.address3?.toLowerCase() || '',
            address4: practice.address4?.toLowerCase() || '',
            address5: practice.address5?.toLowerCase() || '',
            postcode: practice.postcode?.replace(/\s+/g, '').toLowerCase() || ''
          };
  
          // Check if all words in the search term match any part of the practice fields
          return normalizedSearchTerm.every(term => 
            normalizedPractice.prac_name.includes(term) || 
            normalizedPractice.address1.includes(term) ||
            normalizedPractice.address2.includes(term) ||
            normalizedPractice.address3.includes(term) ||
            normalizedPractice.address4.includes(term) ||
            normalizedPractice.address5.includes(term) ||
            normalizedPractice.postcode.includes(term)
          );
        });
  
        // Update displayed practices with search results (no limit for search)
        updateDisplayedPractices(searchResults, selectedPracticeCodes, null);
      } else {
        updateDisplayedPractices(); // Reset to default practices if search term is cleared
      }
    } catch (err) {
      console.error('Error searching practices:', err);
      setError('Unable to search practices. Please try again later.');
    }
    setLoading(false);
  };
  

  const togglePracticeSelection = (practice) => {
    setSelectedPracticeCodes(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(practice.prac_code)) {
        newSelected.delete(practice.prac_code);
      } else {
        newSelected.add(practice.prac_code);
      }
      updateDisplayedPractices(allPractices, newSelected);
      return newSelected;
    });
  };

  const saveAndClose = async () => {
    const practiceCodesToSave = Array.from(selectedPracticeCodes);
    
    // Save to localStorage
    localStorage.setItem('selectedPractices', JSON.stringify(practiceCodesToSave));
    
    // Save to Firebase user profile
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      try {
        await setDoc(userRef, {
          selectedPractices: practiceCodesToSave
        }, { merge: true });
        console.log('Updated practices in Firebase:', practiceCodesToSave);
      } catch (err) {
        console.error('Error saving selected practices to Firebase:', err);
        setError('Unable to save your preferences. Please try again later.');
      }
    }
  
    const selectedPractices = allPractices.filter(p => selectedPracticeCodes.has(p.prac_code));
    onPracticeSelect(selectedPractices);  // Update parent component with selected practices
    onClose();  // Close the modal
  };

  return (
    <Card className="w-full max-w-4xl mx-auto bg-purple-50 p-6">
      <CardContent className="space-y-6">
        <h2 className="text-2xl font-bold text-purple-800">Practice Settings</h2>
        <div> <label className="text-xs text-purple-700 font-medium">Search for your practice below. If you dont see your practice or its incorrect please email support@coooly.co.uk</label></div>
        <div className="flex space-x-2">
          <Input
            type="text"
            placeholder="Search practices..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-grow bg-white"
          />
        </div>
        {loading && <p className="text-purple-800">Loading...</p>}
        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        {searchTerm.trim().length >= 3 && (
          <p className="text-sm text-gray-600">Found {displayedPractices.length} practices for "{searchTerm}"</p>
        )}
        <div className="space-y-2 max-h-96 overflow-y-auto">
          {displayedPractices.map((practice) => (
            <div key={practice.prac_code} className="flex items-center justify-between p-2 border rounded bg-white text-sm">
              <span className="text-purple-800 text-xs">{practice.prac_name} - {practice.address1} - {practice.postcode}</span>
              <Button
                onClick={() => togglePracticeSelection(practice)}
                variant={selectedPracticeCodes.has(practice.prac_code) ? "secondary" : "outline"}
                className={`${
                  selectedPracticeCodes.has(practice.prac_code)
                    ? "bg-purple-600 text-white hover:bg-purple-700"
                    : "bg-white text-purple-800 hover:bg-purple-100"
                } p-1 px-2 text-xs`}>
                {selectedPracticeCodes.has(practice.prac_code) ? "Remove" : "Select"}
              </Button>
            </div>
          ))}
        </div>
        <div className="flex justify-end space-x-2">
          <Button onClick={onClose} variant="outline" className="border-purple-600 text-purple-800 hover:bg-purple-100">Cancel</Button>
          <Button onClick={saveAndClose} className="bg-purple-600 text-white hover:bg-purple-700">Save</Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default PracticeSettings;
