import React, { useState, useEffect } from 'react';
import { Card, CardContent } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Dialog, DialogContent, DialogTrigger, DialogTitle, DialogDescription, DialogFooter } from "./components/ui/dialog";
import { Settings, ChevronDown, ChevronUp, Trash2, FilePlus } from 'lucide-react';
import { Input } from "./components/ui/input";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "./components/ui/select";
import { Calendar } from "./components/ui/calendar";
import { Popover, PopoverTrigger, PopoverContent } from "./components/ui/popover";
import { Alert, AlertDescription } from "./components/ui/alert";
import { format } from "date-fns";
import PracticeSettings from './PracticeSettings';
import { generatePDF } from './InvoicePDFGenerator';
import ProfileSettings from './ProfileSettings';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { practiceDataUtil } from './helpers/practiceDataUtil';
import { getAuth } from 'firebase/auth';
import { localStorageUtil } from './helpers/localStorageUtil';
import { sendInvoiceEmail } from './sendInvoiceEmail';
import PrivacyPolicy from './PrivacyPolicy';

    const formatDate = (date) => {
  if (!date) return '';
  return format(date, "dd/MM/yyyy");
};

const calendarStyles = {
  '.rdp-nav_button': {
    position: 'absolute',
    top: '0.5rem',
  },
  '.rdp-nav_button_previous': {
    left: '0.5rem',
  },
  '.rdp-nav_button_next': {
    right: '0.5rem',
  },
};

const PrivacyPolicyLink = () => (
  <Dialog>
    <DialogTrigger asChild>
      <button className="text-sm text-purple-600 hover:underline">View Privacy Policy</button>
    </DialogTrigger>
    <DialogContent className="max-w-2xl">
      <PrivacyPolicy />
    </DialogContent>
  </Dialog>
);

const HygienistInvoice = ({ user }) => {
  const [showPracticeManagement, setShowPracticeManagement] = useState(false);
  const [rateType, setRateType] = useState('percentage');
  const [rateValue, setRateValue] = useState('');
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [selectedPractices, setSelectedPractices] = useState([]);
  const [entries, setEntries] = useState([
    { date: null, hours: '', patients: [{ count: '', rate: '', description: '' }], isExpanded: true }
  ]);
  const [total, setTotal] = useState(null);
  const [summary, setSummary] = useState([]);
  const [error, setError] = useState('');
  const [openCalendar, setOpenCalendar] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [emailStatus, setEmailStatus] = useState('');
  const [filteredPractices, setFilteredPractices] = useState([]);
  const [showProfileWarning, setShowProfileWarning] = useState(false);

  const auth = getAuth();
  const db = getFirestore();

    // Function to fetch the latest profile data
    const fetchLatestProfileData = async () => {
      if (auth.currentUser) {
        try {
          let userData = localStorageUtil.getItem(`userData_${auth.currentUser.uid}`);
          let needsUpdate = false;
    
          if (!userData) {
            console.log("No user data in local storage, fetching from Firebase");
            needsUpdate = true;
          } else {
            const lastUpdateTime = new Date(userData.lastUpdated);
            const currentTime = new Date();
            // Check if the data is older than 1 hour
            if (currentTime - lastUpdateTime > 60 * 60 * 1000) {
              console.log("Local data is outdated, fetching from Firebase");
              needsUpdate = true;
            }
          }
    
          if (needsUpdate) {
            const userDoc = doc(db, 'users', auth.currentUser.uid);
            const userSnapshot = await getDoc(userDoc);
            if (userSnapshot.exists()) {
              userData = userSnapshot.data();
              userData.lastUpdated = new Date().toISOString();
              localStorageUtil.setItem(`userData_${auth.currentUser.uid}`, userData);
            } else {
              console.log("No user profile found in Firebase");
              return;
            }
          }
    
          // Always fetch the lastInvoiceNumber from Firebase
          const userDoc = doc(db, 'users', auth.currentUser.uid);
          const userSnapshot = await getDoc(userDoc);
          if (userSnapshot.exists()) {
            const firebaseData = userSnapshot.data();
            userData.lastInvoiceNumber = firebaseData.lastInvoiceNumber;
          }
    
          console.log("User profile loaded:", userData);
          setProfileData(userData);
        } catch (error) {
          console.error("Error loading user profile:", error);
        }
      }
    };
  
    useEffect(() => {
      fetchLatestProfileData();
    }, [auth.currentUser, db]);


    const handleSaveProfile = async (data) => {
      setProfileData(data);
      setShowProfileSettings(false);
      await fetchLatestProfileData(); // Fetch the latest data after saving
    };



  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        try {
          const localUserData = localStorageUtil.getItem(`userData_${currentUser.uid}`);
          if (localUserData) {
            console.log("User profile loaded from local storage");
            setProfileData(localUserData);
          } else {
            const userDoc = doc(db, 'users', currentUser.uid);
            const userSnapshot = await getDoc(userDoc);
            if (userSnapshot.exists()) {
              const userData = userSnapshot.data();
              setProfileData(userData);
              localStorageUtil.setItem(`userData_${currentUser.uid}`, userData);
            } else {
              console.log("No user profile found");
            }
          }
        } catch (error) {
          console.error("Error loading user profile:", error);
        }
      }
    });
  
    return () => unsubscribe();
  }, [db]);
  


  useEffect(() => {
    const initializeData = async () => {
      try {
        console.log("Initializing data...");
        
        // Ensure data is reloaded from JSON to local database
        await practiceDataUtil.initializePracticeData(true);

        // Load previously selected practice codes from localStorage
        const savedPracticeCodes = JSON.parse(localStorage.getItem('selectedPractices') || '[]');
        console.log("Saved practice codes:", savedPracticeCodes);
        
        if (savedPracticeCodes.length > 0) {
          // Only load practices if there are saved codes
          const allPractices = await practiceDataUtil.getAllPractices();
          const previouslySelectedPractices = allPractices.filter(practice => 
            savedPracticeCodes.includes(practice.prac_code)
          );
          console.log("Previously selected practices:", previouslySelectedPractices);
          
          setSelectedPractices(previouslySelectedPractices);
          
          if (previouslySelectedPractices.length > 0) {
            setSelectedPractice(previouslySelectedPractices[0].prac_code);
          }
        } else {
          console.log("No saved practices found.");
          setSelectedPractices([]);
          setSelectedPractice(null);
        }
      } catch (error) {
        console.error('Error initializing practice data:', error);
        // Handle error (e.g., show error message to user)
      }
    };

    initializeData();
  }, []);

  const isProfileComplete = () => {
    if (!profileData) return false;
    const requiredFields = ['name', 'address1', 'city', 'postcode', 'bankName', 'sortCode', 'accountNumber'];
    return requiredFields.every(field => profileData[field] && profileData[field].trim() !== '');
  };

  const handleGeneratePDF = async () => {
    if (!isProfileComplete()) {
      setShowProfileWarning(true);
    } else {
      await fetchLatestProfileData(); // Fetch the latest profile data before generating PDF
      if (summary.length > 0 && total !== null && selectedPractice && profileData) {
        try {
          const practiceDetails = selectedPractices.find(practice => practice.prac_code === selectedPractice);
          
          if (practiceDetails) {
            console.log('Calling generatePDF with:', {
              summary,
              total,
              practiceDetails,
              rateType,
              userProfile: {
                ...profileData,
                id: auth.currentUser.uid
              }
            });
            await generatePDF(summary, total, practiceDetails, rateType, {
              ...profileData,
              id: auth.currentUser.uid
            });
          } else {
            setError('Selected practice details not found. Please try again.');
          }
        } catch (error) {
          console.error('Error generating PDF:', error);
          setError('An error occurred while generating the PDF. Please try again.');
        }
      } else {
        setError('Please ensure all required data is available before generating a PDF.');
      }
    }
  };

  const generatePDFWithData = async () => {
    if (summary.length > 0 && total !== null && selectedPractice && profileData) {
      try {
        const practiceDetails = selectedPractices.find(practice => practice.prac_code === selectedPractice);
        
        if (practiceDetails) {
          console.log('Calling generatePDF with:', {
            summary,
            total,
            practiceDetails,
            rateType,
            userProfile: {
              ...profileData,
              id: auth.currentUser.uid // Ensure the id is included
            }
          });
          await generatePDF(summary, total, practiceDetails, rateType, {
            ...profileData,
            id: auth.currentUser.uid
          });
        } else {
          setError('Selected practice details not found. Please try again.');
        }
      } catch (error) {
        console.error('Error generating PDF:', error);
        setError('An error occurred while generating the PDF. Please try again.');
      }
    } else {
      setError('Please ensure all required data is available before generating a PDF.');
    }
  };

  const handlePracticeSelection = (prac_code) => {
    console.log("handlePracticeSelection called with:", prac_code);
    setSelectedPractice(prac_code);
    localStorage.setItem('selectedPractice', prac_code);
    };

    const formatInvoiceDetails = (item) => {
      if (!item || !item.patients || !Array.isArray(item.patients)) {
        console.error('Invalid item structure:', item);
        return 'Error: Invalid data structure';
      }
    
      const uniqueRates = new Map();
      
      item.patients.forEach(patient => {
        const key = `${patient.rate}_${patient.description}`;
        if (uniqueRates.has(key)) {
          uniqueRates.get(key).count += parseInt(patient.count) || 0;
        } else {
          uniqueRates.set(key, { ...patient, count: parseInt(patient.count) || 0 });
        }
      });
    
      return Array.from(uniqueRates.values()).map(patient => {
        let line = `${patient.count}x £${parseFloat(patient.rate).toFixed(2)}`;
        if (item.rateType === 'percentage') {
          line += ` @ ${item.rateValue}%`;
        }
        if (patient.description) {
          line += ` (${patient.description})`;
        }
        return line;
      }).join('\n');
    };
/////////////////////////////////////////

useEffect(() => {
  const savedPractices = localStorage.getItem('selectedPractices');
  if (savedPractices) {
    const savedPracticeCodes = new Set(JSON.parse(savedPractices));
    const filtered = selectedPractices.filter(p => savedPracticeCodes.has(p.prac_code));
    setFilteredPractices(filtered);

    if (filtered.length > 0) {
      setSelectedPractice(filtered[0].prac_code);
    } else {
      setSelectedPractice(null);
    }
  }
}, [selectedPractices]);
    

useEffect(() => {
  const loadSelectedPractices = async () => {
    try {
      const savedPracticeCodes = JSON.parse(localStorage.getItem('selectedPractices') || '[]');
      console.log('Loaded saved practice codes:', savedPracticeCodes);

      let practiceCodesToLoad = savedPracticeCodes;

      if (savedPracticeCodes.length === 0) {
        const db = getFirestore();
        const userDoc = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          practiceCodesToLoad = userData.selectedPractices || [];
          console.log('Loaded practice codes from Firebase:', practiceCodesToLoad);

          localStorage.setItem('selectedPractices', JSON.stringify(practiceCodesToLoad));
        }
      }

      if (practiceCodesToLoad.length > 0) {
        const allPractices = await practiceDataUtil.getAllPractices();
        const loadedPractices = allPractices.filter(p => practiceCodesToLoad.includes(p.prac_code));
        console.log('Loaded practices:', loadedPractices);

        setFilteredPractices(loadedPractices);
        
        // Load the last selected practice from localStorage
        const lastSelectedPractice = localStorage.getItem('selectedPractice');
        if (lastSelectedPractice && loadedPractices.some(p => p.prac_code === lastSelectedPractice)) {
          setSelectedPractice(lastSelectedPractice);
        } else if (loadedPractices.length > 0) {
          setSelectedPractice(loadedPractices[0].prac_code);
        }
      } else {
        setFilteredPractices([]);
        setSelectedPractice(null);
      }
    } catch (error) {
      console.error('Error loading selected practices:', error);
    }
  };

  if (user) {
    loadSelectedPractices();
  }
}, [user]);
    

const addEntry = () => {
  const newEntries = entries.map(entry => ({
    ...entry,
    isExpanded: false
  }));
  setEntries([...newEntries, { 
    date: null, 
    hours: '', 
    description: '',
    patients: [{ count: '', rate: '', description: '' }], 
    isExpanded: true 
  }]);
};

const updateEntry = (dateIndex, field, value) => {
  const newEntries = [...entries];
  if (field === 'date') {
    newEntries[dateIndex].date = value;
  } else if (field === 'hours') {
    newEntries[dateIndex].hours = value;
  } else if (field === 'description') { // Add this condition
    newEntries[dateIndex].description = value;
  } else {
    const [patientIndex, patientField] = field.split('.');
    newEntries[dateIndex].patients[patientIndex][patientField] = value;
  }
  setEntries(newEntries);
};

  const deleteEntry = (indexToDelete) => {
    setEntries(entries.filter((_, index) => index !== indexToDelete));
  };

  const toggleExpand = (index) => {
    const newEntries = entries.map((entry, i) => 
      i === index ? {...entry, isExpanded: !entry.isExpanded} : entry
    );
    setEntries(newEntries);
  };

  const addMorePatients = (dateIndex) => {
    const newEntries = [...entries];
    newEntries[dateIndex].patients.push({ count: '', rate: '' });
    setEntries(newEntries);
  };

  const calculateTotal = () => {
    setError('');
    if (!rateValue || isNaN(parseFloat(rateValue))) {
      setError(`Please enter a valid ${rateType === 'hourly' ? 'hourly rate' : 'percentage rate'}.`);
      return;
    }
  
    if (entries.some(entry => !entry.date)) {
      setError('Please select a date for all entries.');
      return;
    }
  
    let sum = 0;
    const newSummary = [];
  
    entries.forEach(entry => {
      if (!entry.date) return;
  
      let dateTotal = 0;
      let totalPatients = 0;
      let patients = [];
  
      if (rateType === 'hourly') {
        const hours = parseFloat(entry.hours) || 0;
        dateTotal = hours * parseFloat(rateValue);
        patients = [{
          count: hours,
          rate: parseFloat(rateValue),
          description: entry.description || ''
        }];
        totalPatients = 1; // Assume 1 patient for hourly rate
      } else {
        entry.patients.forEach(patient => {
          const count = parseInt(patient.count) || 0;
          const rate = parseFloat(patient.rate) || 0;
          const percentage = parseFloat(rateValue) / 100;
          const subtotal = count * rate * percentage;
          dateTotal += subtotal;
          totalPatients += count;
          
          if (count > 0 && rate > 0) {
            patients.push({
              count,
              rate,
              description: patient.description || ''
            });
          }
        });
      }
  
      if (dateTotal > 0) {
        sum += dateTotal;
        newSummary.push({
          date: entry.date,
          formattedDate: formatDate(entry.date),
          totalPatients,
          patients,
          rateValue: parseFloat(rateValue),
          rateType,
          amount: dateTotal.toFixed(2)
        });
      }
    });
  
    newSummary.sort((a, b) => a.date - b.date);
  
    setTotal(sum.toFixed(2));
    setSummary(newSummary);
  };

  const getEntrySummary = (entry) => {
    if (rateType === 'hourly') {
      return `${entry.hours || 0} hours`;
    } else {
      const totalPatients = entry.patients.reduce((sum, patient) => sum + (parseInt(patient.count) || 0), 0);
      return `${totalPatients} patients`;
    }
  };




  const handleEmailInvoice = async () => {
    if (summary.length > 0 && total !== null && selectedPractice) {
      try {
        setIsEmailSending(true);
        setEmailStatus('Sending...');
        const practiceDetails = selectedPractices.find(practice => practice.prac_code === selectedPractice);
        
        if (practiceDetails && profileData) {
          const result = await sendInvoiceEmail({
            summary,
            total,
            practiceDetails,
            rateType,
            userProfile: profileData
          });
          setEmailStatus('Invoice sent successfully!');
        } else {
          setError('Practice details or user profile not found. Please try again.');
        }
      } catch (error) {
        console.error('Error sending invoice email:', error);
        setEmailStatus('Failed to send invoice. Please try again.');
      } finally {
        setIsEmailSending(false);
      }
    } else {
      setError('Please calculate the invoice and select a practice before sending an email.');
    }
  };
 
  const formatSummaryDate = (date) => {
    if (!date) return '';
    return format(date, "dd/MM/yy");
  };

  return (
    <Card className="w-full max-w-4xl mx-auto p-4 bg-purple-50">
      <CardContent className="space-y-4">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold text-purple-800">Welcome, {user.displayName || 'User'}!</h1>
        </div>
        <div> <label className="text-xs text-purple-700 font-medium">Start by selecting the rate type (Commission type or Hourly)  below and enter the rate value (Commission or hourly rate)</label></div>
        <div className="grid grid-cols-7 gap-2">
  
     
          <div className="col-span-4">
            <label className="text-xs text-purple-700 font-medium">Rate Type</label>
            <Select value={rateType} onValueChange={setRateType}>
              <SelectTrigger className="w-full h-10 text-sm">
                <SelectValue placeholder="Select rate type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="hourly" className="whitespace-nowrap">Hour Rate</SelectItem>
                <SelectItem value="percentage" className="whitespace-nowrap">% Rate Commission</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="col-span-3">
            <label className="text-xs text-purple-700 font-medium">Rate Value</label>
            <Input
              type="number"
              placeholder={rateType === 'hourly' ? 'Hourly rate' : '% rate'}
              value={rateValue}
              onChange={(e) => setRateValue(e.target.value)}
              className="w-full h-10 text-sm"
              maxLength={4}
            />
          </div>
        </div>
  
        <div className="space-y-1">
          <label className="text-xs text-purple-700 font-medium">Select Practice  - use the add practice button on the right to add new practices</label>
          <div className="flex items-center space-x-2">
          <Select 
              value={selectedPractice}
              onValueChange={handlePracticeSelection}
              disabled={selectedPractices.length === 0}
              renderValue={(value) => {
                const practice = selectedPractices.find(p => p.prac_code === value);
                return practice ? (
                  <span style={{ fontSize: '0.75rem' }}>{practice.prac_name} - {practice.postcode}</span>
                ) : (
                  <span style={{ fontSize: '0.75rem' }}>Select a practice</span>
                );
              }}
            >
              <SelectTrigger className="w-full" style={{ fontSize: '11px' }}>
                <SelectValue placeholder="Select a practice" />
              </SelectTrigger>
              <SelectContent>
                {selectedPractices.map((practice) => (
                  <SelectItem className="text-xs"
                    key={practice.prac_code} 
                    value={practice.prac_code}
                  >
                    {practice.prac_name} - {practice.postcode}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Dialog open={showPracticeManagement} onOpenChange={setShowPracticeManagement}>
              <DialogTrigger asChild>
                <Button variant="outline" size="icon" className="text-purple-800 hover:text-purple-600">
                  <FilePlus className="h-5 w-5" />
                </Button>
              </DialogTrigger>
              <DialogContent className="max-w-4xl">
                <PracticeSettings 
                  initialSelectedPractices={selectedPractices}
                  onPracticeSelect={handlePracticeSelection}
                  onClose={() => setShowPracticeManagement(false)}
                />
              </DialogContent>
            </Dialog>
          </div>
        </div>
  
        {/* Settings and Practice Management buttons */}
        <div className="absolute top-0 right-2 flex space-x-2">
          <Dialog open={showPracticeManagement} onOpenChange={setShowPracticeManagement}>
            <DialogTrigger asChild>
              <Button variant="outline" size="icon" className="text-purple-800 hover:text-purple-600">
                <FilePlus className="h-6 w-6" />
              </Button>
            </DialogTrigger>
            <DialogContent className="max-w-4xl">
              <PracticeSettings 
                user={user} 
                initialSelectedPractices={selectedPractices}
                onPracticeSelect={(practices) => {
                  setSelectedPractices(practices);
                  if (practices.length > 0) {
                    setSelectedPractice(practices[0].id);
                  }
                  setShowPracticeManagement(false);
                }}
                onClose={() => setShowPracticeManagement(false)}
              />
            </DialogContent>
          </Dialog>
  
          <Dialog open={showProfileSettings} onOpenChange={setShowProfileSettings}>
            <DialogTrigger asChild>
              <Button variant="ghost" size="icon" className="text-purple-800 hover:text-purple-600">
                <Settings className="h-6 w-6" />
              </Button>
            </DialogTrigger>
            <DialogContent className="max-w-4xl">
            <ProfileSettings 
                onSave={handleSaveProfile} 
                onClose={() => setShowProfileSettings(false)}
                initialData={profileData} />
            </DialogContent>
          </Dialog>
        </div>
  
        {/* Entries */}
        <div className="space-y-1">
          <label className="text-xs text-purple-700 font-medium">Invoice Entries</label>
          {entries.map((entry, dateIndex) => (
            <div key={dateIndex} className="border p-3 rounded-lg bg-white shadow-sm">
              <div className="flex justify-between items-center mb-2">
                <div className="flex-1 flex justify-between items-center">
                  <div className="w-full">
                    <Popover
                      open={openCalendar === dateIndex}
                      onOpenChange={(open) => setOpenCalendar(open ? dateIndex : null)}
                    >
                      <PopoverTrigger asChild>
                        <Button variant="outline" className="w-half h-10 px-3 py-2 text-xs">
                          <span className="truncate">
                            {entry.date ? formatDate(entry.date) : "Pick a date"}
                          </span>
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <div className="p-2">
                          <Calendar
                            mode="single"
                            selected={entry.date}
                            onSelect={(date) => {
                              updateEntry(dateIndex, 'date', date);
                              setOpenCalendar(null);
                            }}
                            initialFocus
                            className="rdp-override"
                          />
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                  <span className="text-xs text-gray-600">{getEntrySummary(entry)}</span>
                </div>
                <div className="flex items-center">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => deleteEntry(dateIndex)}
                    className="mr-2 text-red-500 hover:text-red-700"
                  >
                    <Trash2 size={16} />
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => toggleExpand(dateIndex)}
                  >
                    {entry.isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
                  </Button>
                </div>
              </div>
              
              {entry.isExpanded && (
                <div className="mt-3 space-y-3">
{rateType === 'hourly' ? (
  <>
    <Input
      type="number"
      placeholder="No. of Hours"
      value={entry.hours}
      onChange={(e) => updateEntry(dateIndex, 'hours', e.target.value)}
    />
    <Input
      placeholder="Optional description"
      value={entry.description}
      onChange={(e) => updateEntry(dateIndex, 'description', e.target.value)}
      className="mt-2 w-full text-xs placeholder:text-xs"
    />
  </>
                  ) : (
                    <>
              {entry.patients.map((patient, patientIndex) => (
                <div key={patientIndex} className="space-y-2">
                  <div className="flex space-x-4">
                    <Input
                      placeholder="No. of Patients treated"
                      value={patient.count}
                      onChange={(e) => updateEntry(dateIndex, `${patientIndex}.count`, e.target.value)}
                      className="flex-1 text-xs placeholder:text-xs"
                    />
                    <Input
                      placeholder="Rate charged(£)"
                      value={patient.rate}
                      onChange={(e) => updateEntry(dateIndex, `${patientIndex}.rate`, e.target.value)}
                      className="flex-1 text-xs placeholder:text-xs"
                    />
                  </div>
                  <Input
                    placeholder="Optional description"
                    value={patient.description}
                    onChange={(e) => updateEntry(dateIndex, `${patientIndex}.description`, e.target.value)}
                    className="w-full text-xs placeholder:text-xs"
                  />
                </div>
                        
                      ))}
                      <div className="flex justify-end px-2">
                        <Button 
                          variant="outline" 
                          onClick={() => addMorePatients(dateIndex)} 
                          className="w-full bg-purple-100 text-purple-800 hover:bg-purple-200 text-sm px-2 py-1 whitespace-nowrap"
                        >
                          Add another patient rate type
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
  
        <Button variant="outline" onClick={addEntry} className="w-full">
          Add more dates
        </Button>
  
        <Button onClick={calculateTotal} className="w-full bg-purple-600 text-white hover:bg-purple-700">
          Calculate Invoice
        </Button>
  
        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
  
  {summary.length > 0 && (
  <div className="space-y-2 border p-4 rounded-lg bg-white shadow-sm overflow-x-auto">
    <h3 className="font-bold mb-4 text-lg">Invoice Description</h3>
    <div className={`grid ${rateType === 'hourly' ? 'grid-cols-3' : 'grid-cols-4'} gap-2 text-xs sm:text-sm font-bold bg-purple-200 p-2 rounded`}>
      <div>Date</div>
      {rateType === 'percentage' && <div>No. of Patients</div>}
      <div className="wrap">Description</div>
      <div className="text-right">Total Amount</div>
    </div>
    {summary.map((item, index) => (
      <div key={index} className={`grid ${rateType === 'hourly' ? 'grid-cols-3' : 'grid-cols-4'} gap-2 text-xs sm:text-sm p-2 ${index % 2 === 0 ? 'bg-purple-100' : ''} rounded`}>
        <div>{formatSummaryDate(item.date)}</div>
        {rateType === 'percentage' && <div className="text-center">{item.totalPatients}</div>}
        <div className="wrap">
          {formatInvoiceDetails(item).split('\n').map((line, i) => (
            <div key={i}>{line}</div>
          ))}
        </div>
        <div className="text-right">£{item.amount}</div>
      </div>
    ))}
  </div>
)}
  
        {total !== null && (
          <div className="text-right font-bold text-lg">
            Total: £{total}
          </div>
        )}
        
        {summary.length > 0 && total !== null && (
          <div className="flex space-x-4">
            <Button onClick={handleGeneratePDF} className="w-full bg-purple-600 text-white hover:bg-purple-700">
              Generate PDF
            </Button>
          </div>
        )}
        {emailStatus && <p className="text-sm text-center">{emailStatus}</p>}
        <Dialog open={showProfileWarning} onOpenChange={setShowProfileWarning}>
          <DialogContent className="bg-white border border-purple-200 shadow-lg">
            <DialogTitle className="text-xl font-bold text-purple-800">Profile Information Incomplete</DialogTitle>
            <DialogDescription className="text-purple-700">
              Your profile information is incomplete. This information is needed for the invoice. Would you like to update your profile now, or proceed with generating the PDF without this information?
            </DialogDescription>
            <DialogFooter className="flex flex-col space-y-2 mt-4">
              <Button 
                onClick={() => {
                  setShowProfileWarning(false);
                  generatePDF();
                }}
                className="w-full bg-white border border-purple-500 text-purple-700 hover:bg-purple-100"
              >
                Generate PDF Anyway
              </Button>
              <Button 
                onClick={() => {
                  setShowProfileWarning(false);
                  setShowProfileSettings(true);
                }}
                className="w-full bg-purple-600 text-white hover:bg-purple-700"
              >
                Update Profile
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default HygienistInvoice;