import React from 'react';
import { ScrollArea } from "./components/ui/scroll-area";

const TermsOfService = () => (
  <ScrollArea className="h-[60vh] w-full pr-4">
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Terms of Service for Coooly - Dental Invoice Creator</h2>
      <p>Last updated: 26/09/2024</p>
      
      <div>
        <h3 className="text-lg font-semibold">1. Acceptance of Terms</h3>
        <p>By accessing or using Coooly, you agree to be bound by these Terms of Service. If you disagree with any part of the terms, you may not access the service.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">2. Description of Service</h3>
        <p>Coooly is an invoice generation tool designed for self-employed dental hygienists and nurses to create invoices for services rendered to dental practices.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">3. User Accounts</h3>
        <p>You must create an account to use Coooly. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">4. User Responsibilities</h3>
        <p>You are responsible for the accuracy of the information you input into Coooly for invoice generation. Coooly is not responsible for any errors in invoices resulting from incorrect input.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">5. Intellectual Property</h3>
        <p>The Coooly service and its original content, features, and functionality are owned by Coooly and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">6. Termination</h3>
        <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">7. Limitation of Liability</h3>
        <p>In no event shall Coooly, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">8. Changes to Terms</h3>
        <p>We reserve the right to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">9. Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us at: support@coooly.co.uk</p>
      </div>
    </div>
  </ScrollArea>
);

export default TermsOfService;