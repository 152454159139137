import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase';
import { signInWithPopup, GoogleAuthProvider, OAuthProvider, getAuth } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { encryptData, decryptData } from './encryptionUtil';
import { Card, CardContent } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Dialog, DialogContent, DialogTitle, DialogFooter } from "./components/ui/dialog";
import { Checkbox } from "./components/ui/checkbox";
import { FcGoogle } from 'react-icons/fc';
import { FaMicrosoft } from 'react-icons/fa';
import PrivacyPolicy from './PrivacyPolicy';
import ProfileSettings from './ProfileSettings';
import { Link } from 'react-router-dom';

const LOCAL_STORAGE_KEY = 'user_data';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  if (hasError) {
    return <h1>Something went wrong.</h1>;
  }

  return children;
};

const SplashScreen = ({ onLoginSuccess, onPrivacyPolicyAccepted }) => {
  const [isNewUser, setIsNewUser] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [consentGiven, setConsentGiven] = useState(false);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [showProfileSettings, setShowProfileSettings] = useState(false);

  useEffect(() => {
    console.log('SplashScreen mounted');
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log('Auth state changed:', user ? 'User signed in' : 'No user');
      if (user) {
        handleUserSignIn(user);
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const decryptUserData = (encryptedData) => {
    console.log('Encrypted data:', encryptedData);
    const decrypted = Object.keys(encryptedData).reduce((acc, key) => {
      if (typeof encryptedData[key] === 'string' && key !== 'email' && key !== 'uid' && key !== 'consentGiven') {
        try {
          acc[key] = decryptData(encryptedData[key]);
        } catch (error) {
          console.error(`Error decrypting ${key}:`, error);
          acc[key] = encryptedData[key]; // Use original value if decryption fails
        }
      } else {
        acc[key] = encryptedData[key];
      }
      return acc;
    }, {});
    console.log('Decrypted data:', decrypted);
    return decrypted;
  };

  const handleUserSignIn = async (user) => {
    console.log('Handling user sign in for:', user.uid);
    setLoading(true);
    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      console.log('User document exists:', userDoc.exists());
      
      let userData;
      if (!userDoc.exists()) {
        console.log('New user detected');
        userData = {
          uid: user.uid,
          name: user.displayName || '',
          email: user.email || '',
          provider: user.providerData[0].providerId,
          lastLogin: new Date().toISOString(),
          consentGiven: 'false'
        };
        setIsNewUser(true);
        setShowPrivacyPolicy(true);
      } else {
        console.log('Existing user detected');
        const encryptedData = userDoc.data();
        userData = decryptUserData(encryptedData);
        setConsentGiven(userData.consentGiven === 'true');
        if (userData.consentGiven !== 'true') {
          setShowPrivacyPolicy(true);
        } else {
          onLoginSuccess(user);
          onPrivacyPolicyAccepted();
        }
      }
      setUserData(userData);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userData));
    } catch (error) {
      console.error('Error handling user sign in:', error);
      setError('An error occurred while processing your sign in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async (providerName) => {
    console.log(`Initiating sign in with ${providerName}`);
    setError('');
    try {
      let provider;
      switch (providerName) {
        case 'google':
          provider = new GoogleAuthProvider();
          break;
        case 'microsoft':
          provider = new OAuthProvider('microsoft.com');
          provider.setCustomParameters({
            prompt: 'consent',
            tenant: 'common',
            //redirect_uri: 'https://coooly.co.uk/__/auth/handler'
          });
          break;
        default:
          throw new Error('Invalid provider');
      }
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error(`Error signing in with ${providerName}:`, error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      if (error.email) console.error('Email:', error.email);
      if (error.credential) console.error('Credential:', error.credential);
      setError(`Failed to sign in with ${providerName}. Please try again. Error: ${error.message}`);
    }
  };

  const updateUserData = async (userData) => {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
      throw new Error('No authenticated user found');
    }

    try {
      const encryptedData = Object.keys(userData).reduce((acc, key) => {
        if (typeof userData[key] === 'string' && key !== 'email' && key !== 'uid' && key !== 'consentGiven') {
          acc[key] = encryptData(userData[key]);
        } else {
          acc[key] = userData[key];
        }
        return acc;
      }, {});

      await setDoc(doc(db, 'users', user.uid), encryptedData, { merge: true });
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(userData)); // Store decrypted data in local storage
    } catch (error) {
      console.error('Error updating user data:', error);
      throw new Error('Failed to update user data. Please try again or contact support.');
    }
  };

  const handleConsentSubmit = async () => {
    console.log('Submitting consent');
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (!user) {
        throw new Error('No authenticated user found');
      }

      const updatedUserData = { 
        ...userData, 
        consentGiven: 'true',
        uid: user.uid
      };
      await updateUserData(updatedUserData);
      setConsentGiven(true);
      setShowPrivacyPolicy(false);
      if (isNewUser) {
        setShowProfileSettings(true);
      } else {
        onLoginSuccess(user);
        onPrivacyPolicyAccepted();
      }
    } catch (error) {
      console.error("Error saving consent:", error);
      setError('Failed to save consent. Please try again or contact support.');
    }
  };

  const handleProfileComplete = () => {
    onLoginSuccess(auth.currentUser);
    onPrivacyPolicyAccepted();
  };

  const SignInButton = ({ provider, icon, text }) => (
    <Button
      onClick={() => handleSignIn(provider)}
      className="w-full flex items-center justify-center space-x-2 bg-white text-gray-700 border border-gray-300 hover:bg-gray-100 transition-colors"
    >
      {icon}
      <span>{text}</span>
    </Button>
  );

  console.log('Rendering SplashScreen');
  console.log('showPrivacyPolicy:', showPrivacyPolicy);
  console.log('isNewUser:', isNewUser);
  console.log('loading:', loading);
  console.log('showProfileSettings:', showProfileSettings);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (showPrivacyPolicy) {
    console.log('Rendering privacy policy dialog');
    return (
      <Dialog open={true} onOpenChange={() => {}}>
        <DialogContent className="bg-white max-w-2xl">
          <DialogTitle>Privacy Policy and Consent</DialogTitle>
          <div className="max-h-[60vh] overflow-y-auto">
            <PrivacyPolicy />
          </div>
          <DialogFooter>
            <div className="flex items-center space-x-2">
              <Checkbox id="consent" checked={consentGiven} onCheckedChange={setConsentGiven} />
              <label htmlFor="consent" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                I agree to the privacy policy and give my consent for data processing
              </label>
            </div>
            <Button onClick={handleConsentSubmit} disabled={!consentGiven}>
              {isNewUser ? "Continue" : "Submit"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }
  
  if (showProfileSettings) {
    console.log('Rendering profile settings');
    return (
      <ProfileSettings 
      user={userData}
      onClose={handleProfileComplete}
      onSave={async (updatedProfile) => {
        console.log("Profile updated:", updatedProfile);
        await updateUserData(updatedProfile);
        handleProfileComplete();
        }}
      />
    );
  }

  console.log('Rendering sign-in screen');
  return (
    <ErrorBoundary>
    <div className="min-h-screen bg-gradient-to-b from-purple-100 to-purple-200 flex items-center justify-center p-4">
      <Card className="w-full max-w-md mx-auto bg-white shadow-xl">
        <CardContent className="space-y-6 p-8">
          <h1 className="text-3xl font-bold text-center text-purple-800">Welcome to Coooly</h1>
          
          {/* New content: Brief description of the app */}
          <div className="text-center text-gray-600 space-y-2">
            <p>Simplifying invoicing for self-employed dental hygienists and nurses.</p>
            <p>Coooly streamlines your end-of-month calculations, making it easy to invoice practices for services rendered. </p>
            <p>Say goodbye to complicated billing and hello to efficient financial management.</p>
          </div>

          <p className="text-center text-gray-600 font-semibold">Get started by signing in:</p>
          <div className="space-y-4">
            <SignInButton 
              provider="google" 
              icon={<FcGoogle className="w-5 h-5" />} 
              text="Sign in with Google" 
            />
            <SignInButton 
              provider="microsoft" 
              icon={<FaMicrosoft className="w-5 h-5 text-blue-500" />} 
              text="Sign in with Microsoft" 
            />
          </div>
          {error && <p className="text-red-500 text-center">{error}</p>}
          <div className="text-center mt-4 space-y-2">
              <Link to="/privacy-policy" className="text-sm text-purple-600 hover:underline block">
                View Privacy Policy
              </Link>
              <Link to="/terms-of-service" className="text-sm text-purple-600 hover:underline block">
                View Terms of Service
              </Link>
            </div>
        </CardContent>
      </Card>
    </div>
  </ErrorBoundary>
  );
};

export default SplashScreen;