import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth, db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';
import HygienistInvoice from './HygienistInvoice';
import ProfileSettings from './ProfileSettings';
import PracticeSettings from './PracticeSettings';
import Splashscreen from './Splashscreen';
import { Button } from "./components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./components/ui/dropdown-menu";
import { Dialog, DialogContent, DialogTrigger } from "./components/ui/dialog";
import { Settings, LogOut, User } from 'lucide-react';
import './styles/globals.css';
import PrivacyPolicy from './PrivacyPolicy';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import TermsOfService from './TermsOfService';
import TermsOfServicePage from './TermsOfServicePage';

const Footer = () => (
  <footer className="bg-purple-100 py-0.5 text-center">
    <Dialog>
      <DialogTrigger asChild>
        <button className="text-purple-600 hover:underline text-[10px] leading-tight py-0">Privacy Policy</button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <PrivacyPolicy />
      </DialogContent>
    </Dialog>    <Dialog>
      <DialogTrigger asChild>
        <button className="text-purple-600 hover:underline text-[10px] leading-tight py-0">  Terms Of Service</button>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <TermsOfService />
      </DialogContent>
    </Dialog>
  </footer>
);

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [profileComplete, setProfileComplete] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setPrivacyPolicyAccepted(userData.consentGiven === 'true');
          setProfileComplete(isProfileComplete(userData));
        } else {
          setPrivacyPolicyAccepted(false);
          setProfileComplete(false);
        }
      } else {
        setUser(null);
        setPrivacyPolicyAccepted(false);
        setProfileComplete(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const isProfileComplete = (userData) => {
    const requiredFields = ['name', 'address1', 'city', 'postcode', 'bankName', 'sortCode', 'accountNumber'];
    return requiredFields.every(field => userData[field] && userData[field].trim() !== '');
  };

  const handleSignOut = () => {
    signOut(auth).then(() => {
      setUser(null);
      setPrivacyPolicyAccepted(false);
      setProfileComplete(false);
    }).catch((error) => {
      console.error("Error signing out: ", error);
    });
  };

  const handleCloseProfileSettings = () => {
    setShowProfileSettings(false);
  };

  const handleLoginSuccess = (user) => {
    setUser(user);
  };

  const handlePrivacyPolicyAccepted = () => {
    setPrivacyPolicyAccepted(true);
  };

  const handleProfileComplete = () => {
    setProfileComplete(true);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        {user && (
          <header className="sticky top-0 z-50 w-full bg-purple-100 p-4 flex justify-between items-center">
            <img src="/logo.jpg" height="30" width="80" align="center" alt="Logo" />
            <h1 className="text-xl font-bold text-purple-800"> Invoice Generator</h1>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon" className="text-purple-800 hover:text-purple-600">
                  <Settings className="h-6 w-6" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56 bg-purple-50">
                <DropdownMenuItem onClick={() => setShowProfileSettings(true)} className="flex items-center text-purple-800 hover:bg-purple-200">
                  <User className="mr-2 h-4 w-4" />
                  <span>Profile</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={handleSignOut} className="flex items-center text-purple-800 hover:bg-purple-200">
                  <LogOut className="mr-2 h-4 w-4" />
                  <span>Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </header>
        )}
        <main className="p-4">
          <Routes>
            {/* Public route for Privacy Policy */}
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />

            {/* Routes that require authentication */}
            {user && privacyPolicyAccepted && profileComplete ? (
              <>
                <Route path="/" element={<HygienistInvoice user={user} />} />
                <Route path="/practices" element={<PracticeSettings user={user} />} />
              </>
            ) : (
              <Route path="*" element={
                !user || !privacyPolicyAccepted ? (
                  <Splashscreen onLoginSuccess={handleLoginSuccess} onPrivacyPolicyAccepted={handlePrivacyPolicyAccepted} />
                ) : (
                  <ProfileSettings 
                    user={user} 
                    onClose={handleProfileComplete} 
                    onSave={(updatedProfile) => {
                      console.log("Profile updated:", updatedProfile);
                      handleProfileComplete();
                    }}
                  />
                )
              } />
            )}

            {/* Catch-all route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <Footer />
        <Dialog open={showProfileSettings} onOpenChange={setShowProfileSettings}>
          <DialogContent className="max-w-4xl">
            <ProfileSettings 
              user={user}
              onClose={() => setShowProfileSettings(false)}
              onSave={(updatedProfile) => {
                console.log("Profile updated:", updatedProfile);
                setShowProfileSettings(false);
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    </Router>
  );
}

export default App;