import React, { useState, useEffect } from 'react';
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import { Label } from "./components/ui/label";
import { Card, CardContent } from "./components/ui/card";
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { localStorageUtil } from './helpers/localStorageUtil';
import { encryptData, decryptData } from './encryptionUtil';

const ProfileSettings = ({ onClose, onSave }) => {
  const [profile, setProfile] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    postcode: '',
    bankName: '',
    sortCode: '',
    accountNumber: '',
    selectedPractices: [],
    lastUpdated: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [saveStatus, setSaveStatus] = useState('');

  const auth = getAuth();
  const db = getFirestore();

  const fieldOrder = ['name', 'address1', 'address2', 'city', 'postcode', 'bankName', 'sortCode', 'accountNumber'];

  const fieldLabels = {
    name: 'Full Name',
    address1: 'Address Line 1',
    address2: 'Address Line 2',
    city: 'City',
    postcode: 'Postcode',
    bankName: 'Bank Name',
    sortCode: 'Bank Sort Code',
    accountNumber: 'Account Number'
  };

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (auth.currentUser) {
        try {
          const localUserData = localStorageUtil.getItem(`userData_${auth.currentUser.uid}`);
          const localTimestamp = localStorageUtil.getItem(`lastUpdated_${auth.currentUser.uid}`);
          
          const userDoc = doc(db, 'users', auth.currentUser.uid);
          const userSnapshot = await getDoc(userDoc);
          
          if (userSnapshot.exists()) {
            const firestoreData = userSnapshot.data();
            const firestoreTimestamp = firestoreData.lastUpdated ? firestoreData.lastUpdated.toMillis() : 0;
            
            if (!localUserData || !localTimestamp || firestoreTimestamp > parseInt(localTimestamp)) {
              console.log("Updating local data from Firestore");
              const decryptedData = decryptSensitiveData(firestoreData);
              setProfile({...decryptedData, lastUpdated: firestoreTimestamp});
              localStorageUtil.setItem(`userData_${auth.currentUser.uid}`, firestoreData);
              localStorageUtil.setItem(`lastUpdated_${auth.currentUser.uid}`, firestoreTimestamp.toString());
            } else {
              console.log("Using local data");
              setProfile(decryptSensitiveData(localUserData));
            }
          } else if (localUserData) {
            console.log("No Firestore data, using local data");
            setProfile(decryptSensitiveData(localUserData));
          } else {
            console.log("No existing user profile found");
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
          setError("Failed to load profile. Please try again.");
        }
        setLoading(false);
      } else {
        console.log("No authenticated user found");
        setLoading(false);
      }
    };
    fetchUserProfile();
  }, [auth.currentUser, db]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setProfile(prev => ({ ...prev, [id]: value }));
  };

  const handleSave = async () => {
    if (auth.currentUser) {
      console.log("Attempting to save profile for UID:", auth.currentUser.uid);
      const userDoc = doc(db, 'users', auth.currentUser.uid);
      try {
        setSaveStatus('Saving...');
        const timestamp = Date.now();
        const encryptedProfile = encryptSensitiveData({...profile, lastUpdated: timestamp});
        await setDoc(userDoc, { ...encryptedProfile, lastUpdated: new Date(timestamp) }, { merge: true });
        localStorageUtil.setItem(`userData_${auth.currentUser.uid}`, encryptedProfile);
        localStorageUtil.setItem(`lastUpdated_${auth.currentUser.uid}`, timestamp.toString());
        setError('');
        setSaveStatus('Profile saved successfully');
        console.log("Profile updated successfully");
        
        if (onSave) {
          onSave(encryptedProfile);
        }
        
        if (typeof onClose === 'function') {
          onClose();
        } else {
          console.warn('onClose prop is not provided or is not a function');
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        setError("Failed to save profile. Please try again.");
        setSaveStatus('Save failed');
      }
    } else {
      console.log("No authenticated user found when trying to save");
      setError("You must be logged in to save your profile");
    }
  };
  
  

  const encryptSensitiveData = (data) => {
    const sensitiveFields = ['name', 'address1', 'address2', 'city', 'postcode', 'bankName', 'sortCode', 'accountNumber'];
    const encryptedData = { ...data };
    sensitiveFields.forEach(field => {
      if (data[field]) {
        encryptedData[field] = encryptData(data[field]);
      }
    });
    return encryptedData;
  };

  const decryptSensitiveData = (data) => {
    const sensitiveFields = ['name', 'address1', 'address2', 'city', 'postcode', 'bankName', 'sortCode', 'accountNumber'];
    const decryptedData = { ...data };
    sensitiveFields.forEach(field => {
      if (data[field] && typeof data[field] === 'string') {
        try {
          decryptedData[field] = decryptData(data[field]);
        } catch (error) {
          console.error(`Error decrypting ${field}:`, error);
          decryptedData[field] = data[field]; // Use original value if decryption fails
        }
      }
    });
    return decryptedData;
  };

  if (loading) {
    return <div className="text-purple-800">Loading profile...</div>;
  }

  return (
    <Card className="w-full max-w-2xl mx-auto bg-purple-50 p-6 overflow-y-auto max-h-[80vh]">
      <CardContent className="space-y-4">
        <h2 className="text-2xl font-bold text-purple-800">Profile Settings</h2>
        <div>
        <span className="text-xs text-purple-500">Information below will be used to create a pdf invoice. You can fill it in later.</span>
        </div>
        <div className="space-y-1">
          <Label htmlFor="email" className="text-sm font-medium text-purple-700">Email</Label>
          <Input
            id="email"
            value={auth.currentUser?.email || ''}
            disabled
            className="w-full border-purple-300 bg-gray-100"
          />
        </div>

        {fieldOrder.map((key) => (
          <div key={key} className="space-y-1">
            <Label htmlFor={key} className="text-sm font-medium text-purple-700">
              {fieldLabels[key]}
              <span className="text-xs text-purple-500 ml-1">(This data is encrypted while stored)</span>
            </Label>
            <Input
              id={key}
              value={profile[key] || ''}
              onChange={handleInputChange}
              className="w-full border-purple-300 focus:border-purple-500 focus:ring-purple-500"
            />
          </div>
        ))}
        
        <Button 
          onClick={handleSave}
          className="w-full bg-purple-600 text-white hover:bg-purple-700 mt-4"
        >
          Save Profile
        </Button>

        {error && <div className="text-red-500 mt-2">{error}</div>}
        {saveStatus && <div className="text-green-500 mt-2">{saveStatus}</div>}
      </CardContent>
    </Card>
  );
};

export default ProfileSettings;