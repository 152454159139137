import React from 'react';
import { ScrollArea } from "./components/ui/scroll-area";

const PrivacyPolicy = () => (
  <ScrollArea className="h-[60vh] w-full pr-4">
    <div className="space-y-4">
      <h2 className="text-xl font-bold">Privacy Policy for Dental Invoice Creator</h2>
      <p>Last updated: 18/09/2024</p>
      
      <div>
        <h3 className="text-lg font-semibold">1. Introduction</h3>
        <p>Welcome to Dental Invoice Creator. We are committed to protecting your personal data and respecting your privacy. This Privacy Policy explains how we collect, use, and protect your personal information.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">2. Data We Collect</h3>
        <p>We collect and process the following personal data:</p>
        <ul className="list-disc list-inside">
          <li>Full Name</li>
          <li>Address</li>
          <li>Bank Details (account number and sort code)</li>
          <li>Email address (as provided by your Google or Microsoft account)</li>
          <li>Selected dental practices you work with</li>
        </ul>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">3. How We Use Your Data</h3>
        <p>We use your personal data for the following purposes:</p>
        <ul className="list-disc list-inside">
          <li>To provide and maintain our service</li>
          <li>To generate invoices for your dental work</li>
          <li>To communicate with you about our service</li>
        </ul>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">4. Data Storage and Security</h3>
        <p>Your data is stored securely in our Firebase database. We implement appropriate technical and organizational measures to protect your personal data against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">5. Data Retention</h3>
        <p>We retain your personal data for as long as necessary to provide you with our services and as required by applicable laws. In the dental industry, it's standard practice to retain financial records for 6 years after the end of the financial year they relate to. Therefore, we will retain your data for this period unless you request its deletion earlier.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">6. Your Rights</h3>
        <p>Under GDPR, you have the following rights:</p>
        <ul className="list-disc list-inside">
          <li>The right to access your personal data</li>
          <li>The right to rectify inaccurate personal data</li>
          <li>The right to request the deletion of your personal data</li>
          <li>The right to restrict the processing of your personal data</li>
          <li>The right to data portability</li>
          <li>The right to object to the processing of your personal data</li>
        </ul>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">7. Changes to This Privacy Policy</h3>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</p>
      </div>
      
      <div>
        <h3 className="text-lg font-semibold">8. Contact Us</h3>
        <p>If you have any questions about this Privacy Policy or our data practices, please contact us at:</p>
        <p>Email: support@coooly.co.uk</p>
      </div>
      
      <p>By using our service, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your information as described herein.</p>
    </div>
  </ScrollArea>
);

export default PrivacyPolicy;