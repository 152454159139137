import React from 'react';
import { Link } from 'react-router-dom';
import TermsOfService from './TermsOfService';

const TermsOfServicePage = () => {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
      <TermsOfService />
      <div className="mt-4">
        <Link to="/" className="text-purple-600 hover:underline">Back to Home</Link>
      </div>
    </div>
  );
};

export default TermsOfServicePage;