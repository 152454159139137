// localStorageUtil.js

import { openDB } from 'idb';

const DB_NAME = 'DentalInvoiceApp';
const STORE_NAME = 'practices';
const VERSION = 1;

async function initDB() {
  return openDB(DB_NAME, VERSION, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME, { keyPath: 'id' });
    },
  });
}

export const localStorageUtil = {
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error setting localStorage item:', error);
    }
  },

  getItem: (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error('Error getting localStorage item:', error);
      return null;
    }
  },

  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing localStorage item:', error);
    }
  },

  storePractices: async (practices) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    
    for (const practice of practices) {
      await store.put(practice);
    }

    await tx.done;
  },

  getPractices: async () => {
    const db = await initDB();
    return db.getAll(STORE_NAME);
  },

  getSelectedPractices: async (selectedIds) => {
    const db = await initDB();
    const practices = await Promise.all(
      selectedIds.map(id => db.get(STORE_NAME, id))
    );
    return practices.filter(Boolean); // Remove any undefined results
  },

  clearPractices: async () => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    await tx.objectStore(STORE_NAME).clear();
    await tx.done;
  },
};